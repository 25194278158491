import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';

import { searchCarModel } from '../../api/services';
import toEnglishDigits from '../../helpers/toEnglishDigits';
import useCarModels from '../../hooks/useCarModels';
import ICarModel from '../../interface/ICarModel';
import Icon from '../common/Icon';
import SVGLoader from '../common/SVGLoader';

interface ICarSelectProps {
  onChange?: (carModel: ICarModel) => void;
  selectedEntries?: number[];
  allowMultiple?: boolean;
}

interface CarBrand {
  children: any[];
  name: string;
  id?: number;
}

const CarSelect = ({ onChange, allowMultiple = false, selectedEntries = [] }: ICarSelectProps) => {
  const [currentList, setCurrentList] = useState<any[]>([]);
  const [parentStack, setParentStack] = useState<CarBrand[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [listSet, setListSet] = useState<boolean>(false);
  const { carModels, loading } = useCarModels();
  const listEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (carModels && carModels.length) {
      setCurrentList(carModels);
      setParentStack([
        {
          name: 'همه برندها',
          children: carModels,
        },
      ]);
      setListSet(true);
    }
  }, [carModels]);

  const handleCarClick = (item: any) => {
    if (item.children && item.children.length) {
      setParentStack((prev) => [...prev, item]);
      setCurrentList(item.children);
    } else if (onChange) {
      onChange(item as ICarModel);
    }
  };

  useEffect(() => {
    if (listEl.current) {
      listEl.current.scrollTo({
        top: 0,
        // behavior: 'smooth',
      });
    }
  }, [currentList]);

  useEffect(() => {
    if (searchTerm.length > 2) {
      searchCarModel(searchTerm).then(({ data }) => {
        setCurrentList(data);
      });
    } else if (searchTerm.length === 0) {
      setCurrentList(carModels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <div className="flex h-full flex-col">
      <div className="search-wrap relative mb-3">
        <Icon
          name="search"
          className="w-6 h-6 absolute right-2.5 text-dark-340 transform-gpu top-1/2 -translate-y-1/2"
        />
        <input
          type="text"
          className="block with-icon w-full borderless primary-input"
          placeholder="جستجوی برند و تیپ خودرو"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(toEnglishDigits(e.target.value));
          }}
        />

        {searchTerm && (
          <button
            type="button"
            onClick={() => setSearchTerm('')}
            className="absolute text-dark-340 left-2.5 top-1/2 transform-gpu -translate-y-1/2"
          >
            <Icon name="cancel" className="w-6 h-6" />
          </button>
        )}
      </div>

      <div ref={listEl} className="cars-list px-5 pl-2 -mx-5 -mb-5 flex-1 overflow-y-auto">
        {loading || !listSet ? (
          <SVGLoader className="w-7 h-7 text-neutral-600" />
        ) : (
          <>
            {parentStack && parentStack.length > 1 && (
              <button
                onClick={() => {
                  let parents = parentStack;
                  parents.pop();
                  setCurrentList(parents[parents.length - 1].children);
                  setParentStack(parents);
                }}
                type="button"
                className="block w-full py-4 text-right"
              >
                <Icon name="back" className="w-6 h-6 ml-4 text-dark-800" />
                {parentStack[parentStack.length - 2].name}
              </button>
            )}
            {allowMultiple && parentStack[parentStack.length - 1].id && (
              <div
                className="flex w-full cursor-pointer items-center py-4 hover:bg-gray-100 rounded-lg px-4 pl-2"
                onClick={() => {
                  if (onChange) {
                    onChange(parentStack[parentStack.length - 1] as unknown as ICarModel);
                  }
                }}
              >
                <div className="ml-5">
                  {/* {item.logo ? (
                    <Image className="opacity-70" width={24} height={24} alt={item.name} src={item.logo} />
                  ) : null} */}
                </div>
                همه مدل های {parentStack[parentStack.length - 1].name}
                <input
                  className="mr-auto"
                  checked={selectedEntries.includes(parentStack[parentStack.length - 1].id as number)}
                  type="checkbox"
                />
              </div>
            )}
            {currentList.map((item) => (
              <div
                key={item.id}
                className="flex w-full cursor-pointer items-center py-4 hover:bg-gray-100 rounded-lg px-4 pl-2"
                onClick={() => handleCarClick(item)}
              >
                <div className="ml-5">
                  {item.logo ? (
                    <Image className="opacity-70" width={24} height={24} alt={item.name} src={item.logo} />
                  ) : null}
                </div>
                {item.name}{' '}
                {item.children && item.children.length ? (
                  <Icon className="transform-gpu mr-auto w-6 h-6 rotate-90 text-dark-300" name="expand" />
                ) : (
                  <>
                    {allowMultiple ? (
                      <input
                        checked={
                          !!(
                            selectedEntries.includes(item.id) ||
                            (parentStack[parentStack.length - 1] &&
                              parentStack[parentStack.length - 1].id &&
                              selectedEntries.includes(parentStack[parentStack.length - 1].id as number))
                          )
                        }
                        className="mr-auto"
                        type="checkbox"
                      />
                    ) : null}
                  </>
                )}
              </div>
            ))}
            {!currentList.length && <p className="text-center pt-4">موردی یافت نشد</p>}
          </>
        )}
      </div>
    </div>
  );
};

export default CarSelect;
