import Link from 'next/link';
import { useRouter } from 'next/router';

import Icon from '../common/Icon';

const ServicesIndicator = () => {
  const router = useRouter();

  return (
    <nav className="bg-neutral-200 mb-8">
      <ul className="max-w-410 mx-auto h-20 flex justify-between text-xs sm:text-sm font-black text-neutral-800 text-opacity-40">
        <li className="relative flex-1">
          <Link href="/" passHref>
            <a
              className={`h-full px-1.5 sm:px-5 xl:px-1 flex flex-col justify-center items-center ${
                router.pathname.replace('/', '') === '' ? 'bg-white text-opacity-100 text-neutral-800' : ''
              }`}
            >
              <span className="block">
                <Icon name="inspection" className="w-11 h-11 mb-1.5" />
              </span>
              درخواست کارشناسی
            </a>
          </Link>
        </li>
        <li className="relative flex-1">
          <Link href="/buy-car" passHref>
            <a
              className={`h-full px-1.5 sm:px-5 flex flex-col justify-center items-center ${
                router.pathname.replace('/', '') === 'buy-car' ? 'bg-white text-opacity-100 text-neutral-800' : ''
              }`}
            >
              <span className="block">
                <Icon name="buy-car" className="w-11 h-11 mb-1.5" />
              </span>
              خرید خودرو
            </a>
          </Link>
        </li>
        <li className="relative flex-1">
          <span className="absolute z-2 inset-0 filter flex justify-center items-center text-lg font-black text-dark-900">
            به زودی
          </span>
          <Link href="/sell-car" passHref>
            <a className={`h-full px-1.5 sm:px-5 filter blur-1 flex flex-col justify-center items-center`}>
              <span className="block">
                <Icon name="sell-car" className="w-11 h-11 mb-1.5" />
              </span>
              فروش خودرو
            </a>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default ServicesIndicator;
