import React, { ReactNode, useState } from 'react';
import useSWR from 'swr';

import SVGLoader from '../common/SVGLoader';
import Modal from '../Widgets/Modal';

interface IPageModalProps {
  slug: string;
  render: (handler: (e: React.MouseEvent) => void) => ReactNode;
}

interface IPageModalContentProps {
  slug: string;
}

const PageModalContent = ({ slug }: IPageModalContentProps) => {
  const { data, error } = useSWR(`/wp/v2/pages/?slug=${slug}`);

  return !data && !error ? (
    <SVGLoader className="w-7 h-7 text-neutral-600" />
  ) : (
    <>
      {data?.length > 0 ? (
        <div className="-mt-12">
          <h2 className="text-xl font-black mb-3 text-dark-900">{data[0]?.title?.rendered}</h2>

          <div
            className="post-content text-sm leading-7 font-light text-justify text-dark-900"
            dangerouslySetInnerHTML={{ __html: data[0]?.content?.rendered }}
          />
        </div>
      ) : (
        <div className="h-full flex items-center justify-center">محتوای مورد نظر شما یافت نشد</div>
      )}
    </>
  );
};

const PageModal = ({ slug, render }: IPageModalProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();

    setVisible(!visible);
  };

  return (
    <>
      {render && render(handleClick)}

      {visible && (
        <Modal width="800px" toggle={setVisible}>
          <PageModalContent slug={slug} />
        </Modal>
      )}
    </>
  );
};

export default PageModal;
