import useSWR, { KeyedMutator } from 'swr';

export interface ICarModelsResponse {
  carModels: any[];
  error: any;
  mutate: KeyedMutator<any>;
  loading: boolean;
}

export default function useCarModels(): ICarModelsResponse {
  const { data, error, mutate } = useSWR('/cardan/v1/car-models');

  return {
    carModels: data || [],
    loading: !data && !error,
    error,
    mutate,
  };
}
