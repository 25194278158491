import { ChangeEventHandler } from 'react';

import Icon from '../common/Icon';

export interface ICheckBox {
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: ChangeEventHandler;
}

const CheckBox = ({ className = '', checked = false, disabled = false, onChange, value = '' }: ICheckBox) => {
  return (
    <span className={`chk inline-flex items-center ${className}`}>
      <input
        type="checkbox"
        disabled={disabled}
        name=""
        className="custom-checkbox"
        onChange={onChange}
        checked={checked}
        value={value}
      />
      <span className="box inline-flex items-center justify-center leading-none w-5 h-5 bg-neutral-100">
        <Icon name="check" className="w-3.5 h-3.5 text-green-400" />
      </span>
    </span>
  );
};

export default CheckBox;
